import {
  type FaqQuestion,
  STRUCTURED_DATA_TYPE,
  type StructuredDataType,
} from '@backmarket/http-api/src/api-specs-content/models/faq'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import { prepareJsonLdScript } from '~/utils/seo/schema'

export type SchemaScript = {
  type: string
  innerHTML: string
}

type JSON_LD = {
  '@context': string
  '@type': string
  mainEntity: {
    '@type': string
    name: string
    acceptedAnswer: {
      '@type': string
      text: string
    }
  }[]
}
const toStringQuestions = (questions: FaqQuestion[] = []) => {
  return questions.map((questionItem) => {
    const question = questionItem.title
    const answer = documentToPlainTextString(questionItem.text)

    return { question, answer }
  })
}
function buildFaqSchema(questions: FaqQuestion[] = []): JSON_LD {
  const stringifiedQuestions = toStringQuestions(questions)
  const entityItems = stringifiedQuestions.map((item) => {
    const { question, answer } = item

    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }
  })

  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: entityItems,
  }
}

function shouldNotBuildHeadScript(rendering?: string) {
  const isHtmlOnly = rendering === STRUCTURED_DATA_TYPE.HTML_ONLY

  const isInvalidDataType =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !Object.values(STRUCTURED_DATA_TYPE).includes(rendering as any)

  return isHtmlOnly || isInvalidDataType
}

type Head = {
  script: SchemaScript[]
  __dangerouslyDisableSanitizers: ['script']
}

export function buildFaqHead({
  questions,
  rendering,
}: {
  questions: FaqQuestion[]
  rendering: 'JSON_AND_HTML'
}): Head

export function buildFaqHead({
  questions,
  rendering,
}: {
  questions: FaqQuestion[]
  rendering: 'JSON_ONLY'
}): Head
export function buildFaqHead({
  questions,
  rendering,
}: {
  questions: FaqQuestion[]
  rendering: 'HTML_ONLY'
}): object

export function buildFaqHead({
  questions,
}: {
  questions: FaqQuestion[]
}): object

export function buildFaqHead({
  questions,
  rendering,
}: {
  questions: FaqQuestion[]
  rendering?: StructuredDataType
}): Head | object

export function buildFaqHead({
  questions,
  rendering,
}: {
  questions: FaqQuestion[]
  rendering?: StructuredDataType
}) {
  if (shouldNotBuildHeadScript(rendering)) return {}

  const schema = buildFaqSchema(questions)
  const schemaScript = prepareJsonLdScript(schema)

  return {
    script: [schemaScript],
  }
}
